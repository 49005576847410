header#menu-bar {
    box-shadow: 1px 1px 5px #efefef;
    padding: 0 1%;
    display: flex;
    justify-content: space-between;
    height: 70px;
}

#py-container {
    height: 33px;
    margin: auto 0;
}

#logo-container {
    display: flex;
}

#logo-container > img {
    height: 45px;
    margin: auto 0;
}

#user-info-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#user-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#user-info div {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    color: #808080;
}

#user-info div span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 250px;
}

#user-info div span:first-child {
    font-weight: bolder;
}

#user-logout {
    width: 80px;
    height: 30px;
    margin-left: 15px;
    background-color: orangered;
    color: white;
    border: 1px solid orangered;
    border-radius: 5px;
    font-weight: bold;
    font-size: 14px;;
}

#user-logout:hover {
    opacity: 0.8;
}
